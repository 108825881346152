import React, { useRef } from "react";
import { Field, ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import img1 from "../../assets/images/SiteImg/img1.png";
import Footer from "../../layouts/footer";
import Header from "../../layouts/header";
import { Link, useLocation, useNavigate } from "react-router-dom";
import cross from "../../assets/images/SiteImg/cross.png";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import {
  VerifyUser,
  enroll2ndCourse,
  enrollCourse,
  getDashboard,
  updateBankruptcy,
  updateProfileByUser,
  updateAssociateAttorney
} from "../../api/server";
import { ProgressBar } from "react-bootstrap";
import { ThreeDots } from "react-loader-spinner";
import { login } from "../../store/userSlice";
import { toast } from "react-toastify";
import StripeComponent from "../../common/stripeComponent";
import { Check_Completed_checks } from "../../constants";

const EnrollmentValidationSchema = Yup.object().shape({
  bankruptcytype: Yup.string().required("Bankruptcy Type is required"),
  bankruptcycasenum: Yup.string().required(
    "Bankruptcy Case Number is required"
  ),
});

const DashboardPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get("id");

  let userDataInfo = JSON.parse(localStorage.getItem("user")).data;
  const [getloading, setGetLoading] = useState(true);
  const [courseData, setCourseData] = useState(null);
  const [reTakecourseData, setReTakeCourseData] = useState(null);
  const [certificateData, setCertificateData] = useState();
  const user = useSelector((state) => state.user);
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const isJoint = localStorage.getItem("isJoint");
  const verificationRequired = localStorage.getItem("verificationRequired");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user);
  const [showModal, setShowModal] = useState(false);
  const [showModalSecond, setShowModalSecond] = useState(false);
  const [postLoadingFirst, setPostLoadingFirst] = useState(false);
  const [postLoadingSecond, setPostLoadingSecond] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [courseSecondInfo, setCourseSecondInfo] = useState({
    bankruptcytype: "",
    bankruptcycasenum: "",
  });

  const [loading, setLoading] = useState(false);
  const [userAttorney, setUserAttorney] = useState("");
  const [secondCourseAttorneyCode, setSecondCourseAttorneyCode] = useState("");
  const [atternyName, setAtternyName] = useState("");
  const [atternyEmail, setAtternyEmail] = useState("");
  const [atternyNumber, setAtternyNumber] = useState("");
  const [attCodeValidation, setattCodeValidation] = useState(false);
  const [payStatus, setPayStatus] = useState();

  const [oldcode, setOldcode] = useState("");
  const [oldDEcode, setOldDECode] = useState("")

  const handleEnrollFirst = async (values) => {
    setPostLoadingFirst(true);

    await enrollCourse(
      userId ? "admin" : "user",
      userId ? userId : userData.user.data.id,
      values
    )
      .then((res) => {
        if (!userId) {
          dispatch(login(res.data));
        }
        setPostLoadingFirst(false);
        window.location.reload();
      })
      .catch((err) => {
        setPostLoadingFirst(false);
        if (err.response && err.response.data) {
          toast.error(err.response.data.error);
        } else {
          toast.error("An unexpected error occurred.");
        }
      });
  };

  const handleSubmitFirst = async () => {
    handleEnrollFirst();
  };

  const handleEnrollSecond = async (values) => {
    setPostLoadingSecond(true);

    await enroll2ndCourse(
      userId ? "admin" : "user",
      userId ? userId : userData.user.data.id,
      values
    )
      .then((res) => {
        if (!userId) {
          dispatch(login(res.data));
        }
        setPostLoadingSecond(false);
        window.location.reload();
      })
      .catch((err) => {
        setPostLoadingSecond(false);
        if (err.response && err.response.data && err.response.data.error) {
          toast.error(err.response.data.error);
        } else {
          toast.error("An unexpected error occurred.");
        }
      });
  };

  const handleSubmitSecond = async (values) => {
    handleEnrollSecond(values);
  };

  const handleVerifyAttorney = async (e, mode) => {
    setattCodeValidation(false);
    // e.preventDefault();
    const body = {
      code:mode==="DE"?secondCourseAttorneyCode: userAttorney,
    };
    try {
      setLoading(true);
      const response = await VerifyUser(body);
      if (response.status === 200) {
        const data = response.data;
        valuesSetter(response?.data?.data);
        setOldcode(userAttorney);
        setOldDECode(secondCourseAttorneyCode)
        setLoading(false);

        const st = response.data.data.deliverymethod == "bill" ? "checked" : "";
        setPayStatus(st);
        userDataInfo = {
          ...userDataInfo,
        };
      } else {
        setattCodeValidation(true);
      }
    } catch (error) {
      setattCodeValidation(true);
      console.error("Error fetching states data:", error);
      setLoading(false);
    }
  };

  function valuesSetter(data) {
    setAtternyEmail(data.email);
    setAtternyName(data?.firstname);
    setAtternyNumber(data?.phone);
  }

  //////////////////////// updata dashboard Data////////////////

  useEffect(() => {
    const fetchCourseData = async () => {
      try {
        const response = await getDashboard(
          userId ? "admin" : "user",
          userId ? userId : user.user.data.id,
          isJoint == "true" ? "true" : "false"
        );
        var couponCode;
        const student = response.data.data.student
        const activeCode = response.data.data.student.activeCode

          couponCode = 
            activeCode==="DE" ? 
              student.couponcode_second
              : activeCode ==="CC"?
                student.couponcode_first
                :
                ""
        
        setCertificateData(response.data.data.student);
        valuesSetter({
          email: response.data.data.student.attyemail,
          firstname: response.data.data.student.attorneyname,
          phone: response.data.data.student.attyphone,
        });
        setUserAttorney(couponCode);
        setSecondCourseAttorneyCode(couponCode)
        setOldcode(couponCode);
        setOldDECode(couponCode)
        setCourseData({ data: response.data.data.array });
        setReTakeCourseData({ data: response.data.data.retakeArray });
        setGetLoading(false);
      } catch (error) {
        setGetLoading(false);
        console.error("Error fetching data of dashboard:", error);
      }
    };

    fetchCourseData();
  }, []);

  useEffect(() => {
    if (userAttorney) {
      console.log(certificateData);
      // if (triggerFirst) {

      // } else {
      //   document.getElementById("priceModal2").click();
      //   document.getElementById("dismiss2").click();
      //   setTriggerSecond(false);
      // }
    }
  }, [certificateData]);


  const updateUserAssociateAttorney = async(course)=>{
    
    const body = {
      id:userId ? userId : userData.user.data.id,
      course,
      couponcode_first:userAttorney,
      couponcode_second:secondCourseAttorneyCode,
      paid_first: certificateData.paid_first,
      paid_second: certificateData.paid_second,
      attyemail: atternyEmail,
      attorneyname: atternyName,
      attyphone: atternyNumber,
      courseFirstEnrol:Check_Completed_checks.includes(certificateData.activated_first),
      courseSecondEnroll:Check_Completed_checks.includes(certificateData.activated_second)

    }
 
    return updateAssociateAttorney(body)
  }

  return (
    <div>
      <Header />
      {showModal && (
        <StripeComponent
          showModal={showModal}
          setShowModal={setShowModal}
          isLoading={postLoadingFirst}
          setIsLoading={setPostLoadingFirst}
          pay={handleEnrollFirst}
          currentCourse="first"
          amount={certificateData.amount}
        />
      )}
      {showModalSecond && (
        <StripeComponent
          showModal={showModalSecond}
          setShowModal={setShowModalSecond}
          isLoading={postLoadingSecond}
          setIsLoading={setPostLoadingSecond}
          values={courseSecondInfo}
          pay={handleEnrollSecond}
          currentCourse="second"
          amount={certificateData.amount}
        />
      )}
      <section className="mt-250">
        <div className="theme_container">
          <h1 className="f-26">My Courses</h1>
          {getloading ? (
            <div className="d-flex justify-content-center">
              <ThreeDots
                height="40"
                width="80"
                radius="9"
                color="#4fa94d"
                ariaLabel="three-dots-loading"
                wrapperStyle={{
                  display: "flex",
                  justifyContent: "center",
                }}
                wrapperClassName="spinner"
                visible={true}
              />
            </div>
          ) : (
            <>
              <>
                {/*///////////// 1st Course//////////////// */}
                {isLoggedIn &&
                user &&
                courseData &&
                courseData.data.perFirstCourse > 0 ? (
                  <div className="media_object">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="media_object_img">
                          <img src={img1} alt="img1" />
                        </div>
                      </div>
                      <div className="col-md-9">
                        <h1 className="f-26">Pre-Filing Credit Counseling</h1>
                        <h5 className="f-18 text_success">
                          Take Before Filing
                        </h5>

                        <p className="f-16">
                          Take our fast and informative US Trustee approved*
                          Credit Counseling class, and we'll send your required
                          certificate of completion straight to both you and
                          your attorney!
                        </p>
                        {/* ////////////////Budget analysis Button..........//// */}

                        {courseData.data.perFirstCourse > 33 && (
                          <Link
                            to={
                              userId
                                ? `/addinfomultistepuse?id=${userId}`
                                : "/add-info-multistep"
                            }
                            className="update-analysis"
                            style={{
                              fontSize: "16px",
                              textDecoration: "underline",
                            }}
                          >
                            Budget Analysis
                          </Link>
                        )}
                        {courseData.data.perFirstCourse === 100 &&
                          courseData.data.firstGenerated == "" && (
                            <span className="btn">Certificate Pending</span>
                          )}

                        <div className="row  mt-40" style={{ width: "98%" }}>
                          <div className="col-md-9">
                            <div className="progress_wrap">
                              <label className="f-18 f-700 mb-2">
                                {Math.round(courseData.data.perFirstCourse)}%
                              </label>
                              <ProgressBar
                                className="my-progress"
                                now={Math.round(courseData.data.perFirstCourse)}
                              />

                              {courseData.data.perFirstCourse !== 100 && (
                                <p className="f-16 mt-12">
                                  {" "}
                                  {Math.round(
                                    (18 *
                                      (100 - courseData.data.perFirstCourse)) /
                                      100
                                  )}{" "}
                                  Classes are left
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="progress_btn">
                              {courseData.data.perFirstCourse !== 100 && (
                                <Link
                                  to={
                                    userId
                                      ? `/user-first-course?id=${userId}`
                                      : verificationRequired === "true"
                                      ? "/security-law-questions?course=first"
                                      : "/course"
                                  }
                                >
                                  {" "}
                                  <button
                                    className="btn btn_brnd"
                                    type="submit"
                                  >
                                    Resume
                                  </button>
                                </Link>
                              )}

                              {courseData.data.perFirstCourse === 100 &&
                                courseData.data.firstGenerated &&
                                (courseData.data.firstDownload &&
                                courseData.data.firstDownload !== "yes" ? (
                                  <a
                                    href={courseData.data.firstDownload}
                                    target="_blank"
                                  >
                                    <button
                                      className="btn btn_brnd"
                                      type="submit"
                                    >
                                      Certificate
                                    </button>
                                  </a>
                                ) : (
                                  <button
                                    className="btn btn_brnd tooltips"
                                    type="submit"
                                  >
                                    Certificate
                                  </button>
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : isLoggedIn &&
                  user &&
                  courseData &&
                  courseData.data.perFirstCourse === 0 &&
                  (certificateData.activated_first === "checked" ||
                    certificateData.activated_first === "Checked" ||
                    certificateData.activated_first === "complete" ||
                    certificateData.activated_first === "Complete") ? (
                  <div className="media_object">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="media_object_img">
                          <img src={img1} alt="img1" />
                        </div>
                      </div>
                      <div className="col-md-9">
                        <h1 className="f-26">Pre-Filing Credit Counseling</h1>
                        <h5 className="f-18 text_success">
                          Take Before Filing
                        </h5>

                        <p className="f-16">
                          Take our fast and informative US Trustee approved*
                          Credit Counseling class, and we'll send your required
                          certificate of completion straight to both you and
                          your attorney!
                        </p>
                        {courseData.data.perFirstCourse > 33 && (
                          <Link
                            to={
                              userId
                                ? `/addinfomultistepuse?id=${userId}`
                                : "/add-info-multistep"
                            }
                            className="update-analysis"
                          >
                            Budget Analysis
                          </Link>
                        )}
                        <div className="row  mt-40" style={{ width: "98%" }}>
                          <div className="col-md-9">
                            <div className="progress_wrap">
                              <label className="f-18 f-700 mb-2">
                                {Math.round(courseData.data.perFirstCourse)}%
                              </label>
                              <ProgressBar
                                className="my-progress"
                                now={Math.round(courseData.data.perFirstCourse)}
                              />

                              {courseData.data.perFirstCourse !== 100 && (
                                <p className="f-16 mt-12">
                                  {" "}
                                  {Math.round(
                                    (18 *
                                      (100 - courseData.data.perFirstCourse)) /
                                      100
                                  )}{" "}
                                  Classes are left
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="progress_btn">
                              {/* <p className="f-16 mb-0">class, 10 of 18</p> */}
                              <Link
                                to={
                                  userId
                                    ? `/user-first-course?id=${userId}`
                                    : verificationRequired === "true"
                                    ? "/security-law-questions?course=first"
                                    : "/course"
                                }
                              >
                                <button className="btn btn_brnd" type="submit">
                                  Start Course
                                </button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  isLoggedIn &&
                  user &&
                  courseData && (
                    ///////////////////// 3rd condition//////////////////////////////////
                    <div className="media_object">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="media_object_img">
                            <img src={img1} alt="img1" />
                          </div>
                        </div>
                        <div className="col-md-9">
                          <h1 className="f-26">Pre-Filing Credit Counseling</h1>
                          <h5 className="f-18 text_success">
                            Take Before Filing
                          </h5>

                          <p className="f-16">
                            Take our fast and informative US Trustee approved*
                            Credit Counseling class, and we'll send your
                            required certificate of completion straight to both
                            you and your attorney!
                          </p>

                          <button
                            type="button"
                            className=" btn_brnd  mt-20"
                            data-bs-toggle="modal"
                            data-bs-target="#nonSerious"
                            disabled={postLoadingFirst || postLoadingSecond}
                          >
                            {postLoadingFirst ? (
                              <ThreeDots
                                height="20"
                                width="100"
                                radius="9"
                                color="#4fa94d"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{
                                  display: "block",
                                }}
                                wrapperClassName="spinner"
                                visible={true}
                              />
                            ) : (
                              "Enroll Now"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  )
                )}

                {/*2nd custom Modal */}
                <div
                  className={`modal fade custom_modal `}
                  id="nonSerious"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabIndex="-1"
                  aria-labelledby="SeriousLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog">
                    <div className="modal-content pb-60">
                      <button
                        type="button"
                        className="btn_close"
                        data-bs-dismiss="modal"
                      >
                        <img src={cross} alt="cross" className="cross_btn" />
                      </button>
                      <div className="modal-body">
                        <div className="modal_content">
                          <p className="f-16 text-center mt-12 fw-700">
                            Credit counseling is required within a 180-day
                            window before filing for bankruptcy. If you have not
                            yet filed for bankruptcy and need to take this
                            course, please select "Continue" to sign up. If this
                            is not the class, you need, please select "Go Back."
                          </p>

                          <div className="btn_wrap text-center mt-40">
                            <button
                              className="btn_brnd-outline mr-22 mb-2"
                              data-bs-dismiss="modal"
                              type="button"
                            >
                              Go Back
                            </button>

                            <button
                              className="btn_brnd"
                              data-bs-toggle="modal"
                              data-bs-target="#Serious"
                            >
                              Confirm & Continue
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`modal fade custom_modal attorney_info_modal`}
                  id="Serious"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabIndex="-1"
                  aria-labelledby="SeriousLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog attorney_info_dialog">
                    <div className="modal-content pb-60">
                      <button
                        type="button"
                        className="btn_close"
                        data-bs-dismiss="modal"
                        id="dismiss"
                      >
                        <img src={cross} alt="cross" className="cross_btn" />
                      </button>

                      <div className="modal-body">
                        <div className="modal_content">
                          <h1 className="f-26 text-center">
                            <span className="text_success">
                              Attorney Info (Optional)
                            </span>
                          </h1>

                          <div className="row mt-40">
                            <div className="col-lg-4 col-md-6">
                              <div className="input_wrap">
                                <label className="label-title">
                                  Enter Attorney Code
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={userAttorney}
                                  onChange={(e) => {
                                    setUserAttorney(e?.target?.value);
                                    setattCodeValidation(false);
                                  }}
                                  name="attycode"
                                  placeholder="Enter Attorney code"
                                />
                                {attCodeValidation && (
                                  <p className="text-danger">
                                    Invalid Attorney Code{" "}
                                  </p>
                                )}
                                {/* <ErrorMessage
                          name="attycode"
                          component="div"
                          className="error-message text-danger"
                        /> */}
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                              <button
                                className="btn_brnd-outline mr-22"
                                // disabled={postLoading}
                                type="button"
                                onClick={(e)=>handleVerifyAttorney(e, "CC")}
                              >
                                {loading ? (
                                  <ThreeDots
                                    height="20"
                                    width="52"
                                    radius="9"
                                    color="#4e9c4b"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{
                                      display: "block",
                                    }}
                                    wrapperClassName="spinner"
                                    visible={true}
                                  />
                                ) : (
                                  "Verify"
                                )}
                              </button>
                            </div>
                          </div>
                          <div className="row mt-40">
                            <div className="col-lg-4 col-md-6">
                              <div className="input_wrap">
                                <label className="label-title">
                                  Attorney Email
                                </label>
                                <input
                                  type="email"
                                  className="form-control"
                                  name="attyemail"
                                  placeholder="Attorney Email"
                                  readOnly={true}
                                  value={atternyEmail}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                              <div className="input_wrap">
                                <label className="label-title">
                                  Attorney Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="attorneyname"
                                  placeholder="Attorney Name"
                                  readOnly={true}
                                  value={atternyName}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                              <div className="input_wrap">
                                <label className="label-title">
                                  Attorney Phone
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="attyphone"
                                  placeholder="Attorney Phone"
                                  readOnly={true}
                                  value={atternyNumber}
                                />
                              </div>
                            </div>
                          </div>

                          {userAttorney &&
                            (!atternyNumber ||
                              !atternyEmail ||
                              !atternyName ||
                              userAttorney !== oldcode) && (
                              <h6 className="text-danger text-center">
                                Please verify Attorney code to continue
                              </h6>
                            )}
                          <div className="btn_wrap text-center mt-40">
                            <button
                              className="btn_brnd-outline mr-22 mb-2"
                              data-bs-dismiss="modal"
                              type="button"
                            >
                              Go Back
                            </button>

                            {userAttorney &&
                            (!atternyNumber ||
                              !atternyEmail ||
                              !atternyName ||
                              userAttorney !== oldcode) ? (
                              <></>
                            ) : (
                              <button
                                className="btn_brnd"
                                disabled={postLoading}
                                onClick={() => {
                                  

                                  if (
                                    userAttorney == "" 
                                    ||
                                    certificateData.couponcode_first ===  userAttorney
                                  ) {
                                    document
                                      .getElementById("priceModal1")
                                      .click();
                                    document.getElementById("dismiss").click();
                                  } else {
                                    setPostLoading(true);
                                    // updateProfileByUser(
                                    //   userId ? userId : userData.user.data.id,
                                    //   {
                                    //     ...userData.user.data,
                                    //     couponcode_first: userAttorney,
                                    //     couponcode_second: userAttorney,
                                    //     attyemail: atternyEmail,
                                    //     attorneyname: atternyName,
                                    //     attyphone: atternyNumber,
                                    //     paid_first: payStatus,
                                    //   }
                                    // )
                                    updateUserAssociateAttorney("CC").then((res) => {
                                        toast.success(
                                          "Attorney info updated successfully"
                                        );
                                        setPostLoading(false);
                                        setCertificateData((prev) => ({
                                          ...prev,
                                          paid_first: res.data.data.paid_first,
                                          attypayment:
                                            res.data.data.attypayment,
                                          couponcode_first:
                                            res.data.data.couponcode_first,
                                          totalAmount:
                                            res.data.data.totalAmount,
                                          amount: res.data.data.amount,
                                        }));
                                        userDataInfo = res.data.data;
                                        const data = JSON.parse(
                                          localStorage.getItem("user")
                                        );
                                        localStorage.setItem("user", {
                                          ...data,
                                          data: res.data.data,
                                        });
                                        dispatch(login(res.data));
                                        document
                                          .getElementById("priceModal1")
                                          .click();
                                        document
                                          .getElementById("dismiss")
                                          .click();
                                      })
                                      .catch((err) => {
                                        toast.error(err.response.data.error);
                                        setPostLoading(false);
                                      });
                                  }
                                }}
                              >
                                {postLoading ? (
                                  <ThreeDots
                                    height="20"
                                    width="135"
                                    radius="9"
                                    color="#4fa94d"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{
                                      display: "block",
                                    }}
                                    wrapperClassName="spinner"
                                    visible={true}
                                  />
                                ) : (
                                  "Save & Continue"
                                )}
                              </button>
                            )}
                            {userAttorney &&
                              (!atternyNumber ||
                                !atternyEmail ||
                                !atternyName ||
                                userAttorney !== oldcode) && (
                                <button
                                  className="btn_brnd ms-md-4 mt-2 mt-md-0"
                                  disabled={postLoading}
                                  onClick={() => {
                                    document
                                      .getElementById("priceModal1")
                                      .click();
                                    document.getElementById("dismiss").click();
                                  }}
                                >
                                  Skip
                                </button>
                              )}
                            <button
                              id="priceModal1"
                              data-bs-target="#Serious121"
                              data-bs-toggle="modal"
                              style={{ visibility: "hidden" }}
                            ></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`modal fade custom_modal attorney_info_modal`}
                  id="Serious121"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabIndex="-1"
                  aria-labelledby="SeriousLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog attorney_info_dialog">
                    <div className="modal-content pb-60">
                      <button
                        type="button"
                        className="btn_close"
                        data-bs-dismiss="modal"
                      >
                        <img src={cross} alt="cross" className="cross_btn" />
                      </button>

                      <div className="modal-body">
                        <div className="modal_content">
                          <h1 className="f-26 text-center">
                            <span className="text_success">Note</span>
                          </h1>
                          {certificateData.paid_first === "" ? (
                            <>
                              {certificateData.attypayment == "no" && (
                                <p className="f-16 text-center mt-12 fw-700">
                                  The price for the course is
                                  <span className="text_success">
                                    {" "}
                                    $
                                    {certificateData.attypayment === "no" &&
                                    certificateData.couponcode_first !== ""
                                      ? 39.99
                                      : 19.99}{" "}
                                  </span>
                                </p>
                              )}
                              {certificateData.attypayment == null && (
                                <p className="f-16 text-center mt-12 fw-700">
                                  The price for the course is
                                  <span className="text_success"> $ 19.99</span>
                                </p>
                              )}
                            </>
                          ) : (
                            certificateData.attypayment == "no" && (
                              <p className="f-16 text-center mt-12 fw-700">
                                The amount for the course is paid
                              </p>
                            )
                          )}

                          {certificateData.attypayment == "yes" && (
                            <p className="f-16 text-center mt-12 fw-700">
                              Amount will be paid by your attorney
                            </p>
                          )}
                          {certificateData.paid_first === "" ? (
                            <>
                              {certificateData.totalAmount !==
                                certificateData.amount &&
                                certificateData.attypayment == "no" && (
                                  <p className="f-16 text-center fw-700">
                                    Amount Payable
                                    <span className="text_success">
                                      {" "}
                                      $
                                      {Number(certificateData.amount).toFixed(
                                        2
                                      )}{" "}
                                    </span>
                                  </p>
                                )}
                            </>
                          ) : (
                            <></>
                          )}

                          <div className="btn_wrap text-center mt-40">
                            <button
                              className="btn_brnd-outline mr-22 mb-2"
                              data-bs-dismiss="modal"
                              type="button"
                            >
                              Go Back
                            </button>

                            <button
                              className=" btn_brnd  mt-20"
                              data-bs-dismiss="modal"
                              onClick={() => {
                                if (certificateData.paid_first === "") {
                                  if (
                                    certificateData.attypayment === "no" &&
                                    certificateData.amount > 0
                                  ) {
                                    setShowModal(true);
                                  } else {
                                    handleSubmitFirst();
                                  }
                                } else {
                                  handleSubmitFirst();
                                }
                              }}
                              type={
                                (certificateData.attypayment === "yes" &&
                                  certificateData.amount === 0) ||
                                certificateData.paid_first !== ""
                                  ? "submit"
                                  : "button"
                              }
                            >
                              Confirm & Continue
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/*///////////// 2nd Course//////////////// */}

                {isLoggedIn &&
                user &&
                courseData &&
                courseData.data.perSecondCourse > 0 ? (
                  <div className="media_object">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="media_object_img">
                          <img src={img1} alt="img1" />
                        </div>
                      </div>
                      <div className="col-md-9">
                        <h1 className="f-26">
                          Post Bankruptcy debtor education
                        </h1>
                        <h5 className="f-18 text_success">Take After Filing</h5>

                        <p className="f-16">
                          Take our fast and informative US Trustee approved*
                          Credit Counseling class, and we'll send your required
                          certificate of completion straight to both you and
                          your attorney!
                        </p>
                        {courseData.data.perSecondCourse > 27 && (
                          <Link
                            to={
                              userId
                                ? `/net-worth-user?id=${userId}`
                                : "/net-worth?from=dashboard"
                            }
                            className="update-analysis mx-2"
                            style={{
                              fontSize: "16px",
                              textDecoration: "underline",
                            }}
                          >
                            Net worth worksheet
                          </Link>
                        )}
                        {courseData.data.perSecondCourse > 43 && (
                          <Link
                            to={
                              userId
                                ? `/spending-plan-user?id=${userId}`
                                : "/spending-plan?from=dashboard"
                            }
                            className="update-analysis mx-2"
                            style={{
                              fontSize: "16px",
                              textDecoration: "underline",
                            }}
                          >
                            Spending Plan
                          </Link>
                        )}

                        {/* {courseData.data.perSecondCourse > 33 && (
                        <Link
                          to={
                            userId
                              ? `/addinfomultistepuse?id=${userId}`
                              : "/add-info-multistep"
                          }
                          className="update-analysis mx-2"
                          style={{ fontSize: "16px" }}
                        >
                          Pay for this session
                        </Link>
                      )} */}
                        {courseData.data.perSecondCourse >= 0 && (
                          <a
                            href={courseData.data.courseMaterial}
                            target="_blank"
                            className="update-analysis mx-2"
                            style={{
                              fontSize: "16px",
                              textDecoration: "underline",
                            }}
                          >
                            Print course material
                          </a>
                        )}
                        {courseData.data.perSecondCourse === 100 &&
                          courseData.data.secondGenerated == "" && (
                            <span className="btn">Certificate Pending</span>
                          )}
                        <div className="row  mt-40" style={{ width: "98%" }}>
                          <div className="col-md-9">
                            <div className="progress_wrap">
                              <label className="f-18 f-700 mb-2">
                                {Math.round(courseData.data.perSecondCourse)}%
                              </label>
                              <ProgressBar
                                className="my-progress"
                                now={Math.round(
                                  courseData.data.perSecondCourse
                                )}
                              />

                              {courseData.data.perSecondCourse !== 100 && (
                                <p className="f-16 mt-12">
                                  {" "}
                                  {Math.round(
                                    (51 *
                                      (100 - courseData.data.perSecondCourse)) /
                                      100
                                  )}{" "}
                                  Classes are left
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="progress_btn">
                              {/* <p className="f-16 mb-0">class, 10 of 18</p> */}
                              {courseData.data.perSecondCourse !== 100 && (
                                <Link
                                  to={
                                    courseData.data?.quizSecondPending
                                      ? userId
                                        ? `/review-questions-user?id=${userId}`
                                        : "/review-questions"
                                      : userId
                                      ? `/user-second-course?id=${userId}`
                                      : verificationRequired === "true"
                                      ? "/security-law-questions?course=second"
                                      : "/2nd-course"
                                  }
                                >
                                  {" "}
                                  <button
                                    className="btn btn_brnd"
                                    type="submit"
                                  >
                                    Resume
                                  </button>
                                </Link>
                              )}

                              {courseData.data.perSecondCourse === 100 &&
                                courseData.data.secondGenerated &&
                                (courseData.data.secondDownload &&
                                courseData.data.secondDownload !== "yes" ? (
                                  <a
                                    href={courseData.data.secondDownload}
                                    target="_blank"
                                  >
                                    <button
                                      className="btn btn_brnd"
                                      type="submit"
                                    >
                                      Certificate
                                    </button>
                                  </a>
                                ) : (
                                  <button
                                    className="btn btn_brnd tooltips"
                                    type="submit"
                                  >
                                    Certificate
                                  </button>
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : isLoggedIn &&
                  user &&
                  courseData &&
                  courseData.data.perSecondCourse === 0 &&
                  (certificateData.activated_second === "checked" ||
                    certificateData.activated_second === "Checked" ||
                    certificateData.activated_second === "complete" ||
                    certificateData.activated_second === "Complete") ? (
                  <div className="media_object">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="media_object_img">
                          <img src={img1} alt="img1" />
                        </div>
                      </div>
                      <div className="col-md-9">
                        <h1 className="f-26">
                          Post Bankruptcy debtor education
                        </h1>
                        <h5 className="f-18 text_success">Take After Filing</h5>

                        <p className="f-16">
                          Take our fast and informative US Trustee approved*
                          Credit Counseling class, and we'll send your required
                          certificate of completion straight to both you and
                          your attorney!
                        </p>
                        {courseData.data.perSecondCourse >= 0 && (
                          <a
                            href={courseData.data.courseMaterial}
                            target="_blank"
                            className="update-analysis mx-2"
                            style={{
                              fontSize: "16px",
                              textDecoration: "underline",
                            }}
                          >
                            Print course material
                          </a>
                        )}
                        <div className="row  mt-40" style={{ width: "98%" }}>
                          <div className="col-md-9">
                            <div className="progress_wrap">
                              <label className="f-18 f-700 mb-2">
                                {Math.round(courseData.data.perSecondCourse)}%
                              </label>
                              <ProgressBar
                                className="my-progress"
                                now={Math.round(
                                  courseData.data.perSecondCourse
                                )}
                              />

                              {courseData.data.perSecondCourse !== 100 && (
                                <p className="f-16 mt-12">
                                  {" "}
                                  {Math.round(
                                    (51 *
                                      (100 - courseData.data.perSecondCourse)) /
                                      100
                                  )}{" "}
                                  Classes are left
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-3">
                            {certificateData.activated_second &&
                            certificateData.bankruptcycasenum ? (
                              <div className="progress_btn">
                                <Link
                                  to={
                                    userId
                                      ? `/user-second-course?id=${userId}`
                                      : verificationRequired === "true"
                                      ? "/security-law-questions?course=second"
                                      : "/2nd-course"
                                  }
                                >
                                  <button
                                    className="btn btn_brnd"
                                    type="button"
                                    // data-bs-toggle="modal"
                                    // data-bs-target="#SecondSerious"
                                  >
                                    Start Course
                                  </button>
                                </Link>
                              </div>
                            ) : (
                              <div className="progress_btn">
                                <button
                                  className="btn btn_brnd"
                                  type="button"
                                  data-bs-toggle="modal"
                                  data-bs-target="#SecondSerious"
                                >
                                  Start Course
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  isLoggedIn &&
                  user &&
                  courseData && (
                    <div className="media_object">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="media_object_img">
                            <img src={img1} alt="img1" />
                          </div>
                        </div>
                        <div className="col-md-9">
                          <h1 className="f-26">
                            Post Bankruptcy debtor education
                          </h1>
                          <h5 className="f-18 text_success">
                            Take After Filing
                          </h5>

                          <p className="f-16">
                            It's time to take your required post-bankruptcy
                            debtor education class! As usual, your certificate
                            of completion is sent to both you and your attorney.
                          </p>

                          <button
                            type="button"
                            className=" btn_brnd  mt-20"
                            data-bs-toggle="modal"
                            data-bs-target="#Serious1"
                            disabled={postLoadingFirst || postLoadingSecond}
                          >
                            {postLoadingSecond ? (
                              <ThreeDots
                                height="20"
                                width="100"
                                radius="9"
                                color="#4fa94d"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{
                                  display: "block",
                                }}
                                wrapperClassName="spinner"
                                visible={true}
                              />
                            ) : (
                              "Enroll Now"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  )
                )}

                {/*2nd custom Modal */}
                <div
                  className={`modal fade custom_modal `}
                  id="SecondSerious"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabIndex="-1"
                  aria-labelledby="SeriousLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog">
                    <Formik
                      initialValues={courseSecondInfo}
                      validationSchema={EnrollmentValidationSchema}
                      onSubmit={async (
                        values,
                        { resetForm, setSubmitting }
                      ) => {
                        setSubmitting(true);
                        await updateBankruptcy(
                          userId ? "admin" : "user",
                          userId ? userId : userData.user.data.id,
                          values
                        )
                          .then((res) => {
                            document.getElementById("bankDismiss").click();
                            navigate(
                              userId
                                ? `/user-second-course?id=${userId}`
                                : verificationRequired === "true"
                                ? "/security-law-questions?course=second"
                                : "/2nd-course"
                            );
                            resetForm();
                            setSubmitting(false);
                          })
                          .catch((err) => {
                            toast.error(err.response.data.error);
                            setSubmitting(false);
                          });
                      }}
                      enableReinitialize={true}
                    >
                      {({ values, setValues, setTouched, isSubmitting }) => (
                        <>
                          <Form>
                            <div className="modal-content pb-60">
                              <button
                                type="button"
                                className="btn_close"
                                data-bs-dismiss="modal"
                                id="bankDismiss"
                                onClick={() => {
                                  setTouched({
                                    bankruptcytype: false,
                                    bankruptcycasenum: false,
                                  });
                                  setCourseSecondInfo({
                                    bankruptcytype: "",
                                    bankruptcycasenum: "",
                                  });
                                }}
                              >
                                <img
                                  src={cross}
                                  alt="cross"
                                  className="cross_btn"
                                />
                              </button>
                              <div className="modal-body">
                                <div className="modal_content">
                                  <div className="row mt-40">
                                    <div
                                      className="col-md-12 CreditCounseling_inner"
                                      style={{ paddingLeft: "12px" }}
                                      id="Bankruptcy"
                                    >
                                      <div className="question">
                                        <div className="question_text">
                                          <h5 className="f-16 ">
                                            Bankruptcy Type
                                          </h5>
                                        </div>
                                      </div>
                                      <div
                                        className="radio_wrap_question"
                                        id="chapter"
                                      >
                                        <div className="iagree_radio mb-20">
                                          <Field
                                            type="radio"
                                            name="bankruptcytype"
                                            value="7"
                                            id="goals-yes"
                                            className=" "
                                          />
                                          <label htmlFor="goals-yes">
                                            Chapter 7
                                          </label>
                                        </div>
                                        <div className="iagree_radio">
                                          <Field
                                            type="radio"
                                            name="bankruptcytype"
                                            value="13"
                                            id="goals-no"
                                            className=" "
                                          />
                                          <label htmlFor="goals-no">
                                            Chapter 13{" "}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div style={{ marginTop: "-50px" }}>
                                      <ErrorMessage
                                        name="bankruptcytype"
                                        component="div"
                                        className="error text-danger"
                                      />
                                    </div>
                                    <div className="col-md-12 row">
                                      <div className="col-md-6">
                                        <div className="input_wrap">
                                          <label className="label-title">
                                            Bankruptcy Case Number
                                          </label>
                                          <Field
                                            type="text"
                                            placeholder="12-34567"
                                            name="bankruptcycasenum"
                                            className="form-control"
                                            maxlength="8"
                                            onChange={(e) => {
                                              if (
                                                e.target.value[
                                                  e.target.value.length - 1
                                                ] !== " "
                                              ) {
                                                if (
                                                  e.target.value.length === 2
                                                ) {
                                                  setValues({
                                                    ...values,
                                                    bankruptcycasenum:
                                                      e.target.value + "-",
                                                  });
                                                } else {
                                                  setValues({
                                                    ...values,
                                                    bankruptcycasenum:
                                                      e.target.value,
                                                  });
                                                }
                                              }
                                            }}
                                            onKeyDown={(e) => {
                                              if (
                                                e.key === "Backspace" &&
                                                values.bankruptcycasenum
                                                  .length === 3
                                              ) {
                                                setValues({
                                                  ...values,
                                                  bankruptcycasenum:
                                                    e.target.value.slice(0, -1),
                                                });
                                              }
                                            }}
                                          />
                                          <ErrorMessage
                                            name="bankruptcycasenum"
                                            component="div"
                                            className="error text-danger"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="btn_wrap text-center">
                                    <button
                                      className="btn_brnd-outline mr-22 mb-2"
                                      data-bs-dismiss="modal"
                                      type="button"
                                      onClick={() => {
                                        setTouched({
                                          bankruptcytype: false,
                                          bankruptcycasenum: false,
                                        });
                                        setCourseSecondInfo({
                                          bankruptcytype: "",
                                          bankruptcycasenum: "",
                                        });
                                      }}
                                    >
                                      Go Back
                                    </button>

                                    <button
                                      type="submit"
                                      disabled={isSubmitting}
                                      className=" btn_brnd  mt-20"
                                    >
                                      {isSubmitting ? (
                                        <ThreeDots
                                          height="20"
                                          width="100"
                                          radius="9"
                                          color="#4fa94d"
                                          ariaLabel="three-dots-loading"
                                          wrapperStyle={{
                                            display: "block",
                                          }}
                                          wrapperClassName="spinner"
                                          visible={true}
                                        />
                                      ) : (
                                        "Confirm & Continue"
                                      )}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Form>
                        </>
                      )}
                    </Formik>
                  </div>
                </div>
                <div
                  className={`modal fade custom_modal `}
                  id="Serious1"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabIndex="-1"
                  aria-labelledby="SeriousLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog">
                    <div className="modal-content pb-60">
                      <button
                        type="button"
                        className="btn_close"
                        data-bs-dismiss="modal"
                      >
                        <img src={cross} alt="cross" className="cross_btn" />
                      </button>
                      <div className="modal-body">
                        <div className="modal_content">
                          <p className="f-16 text-center mt-12 fw-700">
                            NOTE: Pre-discharge bankruptcy education (or debtor
                            education/personal financial management) is required
                            after you file for bankruptcy and obtain your case
                            number. If you have already filed for bankruptcy and
                            you wish to take this course, please select "Confirm
                            & Continue" to sign up. If this is not the class,
                            you need, please select "Go Back."{" "}
                          </p>

                          <div className="btn_wrap text-center mt-40">
                            <button
                              className="btn_brnd-outline mr-22 mb-2"
                              data-bs-dismiss="modal"
                              type="button"
                            >
                              Go Back
                            </button>

                            <button
                              type="button"
                              className=" btn_brnd  mt-20"
                              data-bs-toggle="modal"
                              data-bs-target="#Serious2"
                            >
                              Confirm & Continue
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`modal fade custom_modal attorney_info_modal`}
                  id="Serious2"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabIndex="-1"
                  aria-labelledby="SeriousLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog attorney_info_dialog">
                    <div className="modal-content pb-60">
                      <button
                        type="button"
                        className="btn_close"
                        data-bs-dismiss="modal"
                        id="dismiss2"
                        disabled={postLoading}
                      >
                        <img src={cross} alt="cross" className="cross_btn" />
                      </button>

                      <div className="modal-body">
                        <div className="modal_content">
                          <h1 className="f-26 text-center">
                            <span className="text_success">
                              Attorney Info (Optional)
                            </span>
                          </h1>

                          <div className="row mt-40">
                            <div className="col-lg-4 col-md-6">
                              <div className="input_wrap">
                                <label className="label-title">
                                  Enter Attorney Code
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={secondCourseAttorneyCode}
                                  onChange={(e) => {
                                    setSecondCourseAttorneyCode(e?.target?.value);
                                    setattCodeValidation(false);
                                  }}
                                  name="attycode"
                                  placeholder="Enter Attorney code"
                                />
                                {attCodeValidation && (
                                  <p className="text-danger">
                                    Invalid Attorney Code{" "}
                                  </p>
                                )}
                                {/* <ErrorMessage
                          name="attycode"
                          component="div"
                          className="error-message text-danger"
                        /> */}
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                              <button
                                className="btn_brnd-outline mr-22"
                                // disabled={postLoading}
                                type="button"
                                onClick={(e)=>handleVerifyAttorney(e, "DE")}
                              >
                                {loading ? (
                                  <ThreeDots
                                    height="20"
                                    width="52"
                                    radius="9"
                                    color="#ed1717"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{
                                      display: "block",
                                    }}
                                    wrapperClassName="spinner"
                                    visible={true}
                                  />
                                ) : (
                                  "Verify"
                                )}
                              </button>
                            </div>
                          </div>
                          <div className="row mt-40">
                            <div className="col-lg-4 col-md-6">
                              <div className="input_wrap">
                                <label className="label-title">
                                  Attorney Email
                                </label>
                                <input
                                  type="email"
                                  className="form-control"
                                  name="attyemail"
                                  placeholder="Attorney Email"
                                  readOnly={true}
                                  value={atternyEmail}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                              <div className="input_wrap">
                                <label className="label-title">
                                  Attorney Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="attorneyname"
                                  placeholder="Attorney Name"
                                  readOnly={true}
                                  value={atternyName}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                              <div className="input_wrap">
                                <label className="label-title">
                                  Attorney Phone
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="attyphone"
                                  placeholder="Attorney Phone"
                                  readOnly={true}
                                  value={atternyNumber}
                                />
                              </div>
                            </div>
                          </div>

                          {secondCourseAttorneyCode &&
                            (!atternyNumber ||
                              !atternyEmail ||
                              !atternyName ||
                              secondCourseAttorneyCode !== oldDEcode) && (
                              <h6 className="text-danger text-center">
                                Please verify Attorney code to continue
                              </h6>
                            )}
                          <div className="btn_wrap text-center mt-40">
                            <button
                              className="btn_brnd-outline mr-22 mb-2"
                              data-bs-dismiss="modal"
                              type="button"
                              disabled={postLoading}
                            >
                              Go Back
                            </button>

                            {secondCourseAttorneyCode &&
                            (!secondCourseAttorneyCode ||
                              !atternyEmail ||
                              !atternyName ||
                              secondCourseAttorneyCode !== oldDEcode) ? (
                              <></>
                            ) : (
                              <button
                                className="btn_brnd"
                                disabled={postLoading}
                                onClick={() => {
                                  
                                  if (
                                    secondCourseAttorneyCode == "" ||
                                    certificateData.couponcode_second ===
                                      secondCourseAttorneyCode
                                  ) {
                                    document
                                      .getElementById("priceModal2")
                                      .click();
                                    document.getElementById("dismiss2").click();
                                  } else {
                                    setPostLoading(true);
                                    // updateUserAssociateAttorney()
                                    // updateAssociateAttorney(
                                    //   {
                                    //     _id:userId ? userId : userData.user.data.id,
                                    //     course:"DE",
                                    //     couponcode_first: userAttorney,
                                    //     couponcode_second: userAttorney,
                                    //     paid_first: payStatus,
                                    //     paid_second: payStatus,
                                    //     attyemail: atternyEmail,
                                    //     attorneyname: atternyName,
                                    //     attyphone: atternyNumber,
                                    //     courseFirstEnrol:"",
                                    //     courseSecondEnroll:false

                                    //   }
                                    // )
                                    // updateProfileByUser(
                                    //   userId ? userId : userData.user.data.id,
                                    //   {
                                    //     ...userData.user.data,
                                    //     couponcode_first: userAttorney,
                                    //     couponcode_second: userAttorney,
                                    //     attyemail: atternyEmail,
                                    //     attorneyname: atternyName,
                                    //     attyphone: atternyNumber,
                                    //     paid_second: payStatus,
                                    //   }
                                    // )
                                    updateUserAssociateAttorney("DE")
                                      .then((res) => {
                                        toast.success(
                                          "Attorney info updated successfully"
                                        );
                                        setPostLoading(false);
                                        setCertificateData((prev) => ({
                                          ...prev,

                                          attypayment:
                                            res.data.data.attypayment,
                                          couponecode_second:res.data.data.couponcode_second,
                                          paid_second:res.data.data.paid_second,
                                          totalAmount:res.data.data.totalAmount,
                                          amount: res.data.data.amount,
                                        }));
                                        userDataInfo = res.data.data;
                                        const data = JSON.parse(
                                          localStorage.getItem("user")
                                        );
                                        localStorage.setItem("user", {
                                          ...data,
                                          data: res.data.data,
                                        });
                                        dispatch(login(res.data));
                                        document
                                          .getElementById("priceModal2")
                                          .click();
                                        document
                                          .getElementById("dismiss2")
                                          .click();
                                      })
                                      .catch((err) => {
                                        toast.error(err.response.data.error);
                                        setPostLoading(false);
                                      });
                                  }
                                }}
                              >
                                {postLoading ? (
                                  <ThreeDots
                                    height="20"
                                    width="135"
                                    radius="9"
                                    color="#4fa94d"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{
                                      display: "block",
                                    }}
                                    wrapperClassName="spinner"
                                    visible={true}
                                  />
                                ) : (
                                  "Save & Continue"
                                )}
                              </button>
                            )}
                            {userAttorney &&
                              (!atternyNumber ||
                                !atternyEmail ||
                                !atternyName ||
                                userAttorney !== oldcode) && (
                                <button
                                  className="btn_brnd ms-md-4 mt-2 mt-md-0"
                                  disabled={postLoading}
                                  onClick={() => {
                                    document
                                      .getElementById("priceModal2")
                                      .click();
                                    document.getElementById("dismiss2").click();
                                  }}
                                >
                                  Skip
                                </button>
                              )}
                            <button
                              id="priceModal2"
                              data-bs-target="#Serious222"
                              data-bs-toggle="modal"
                              style={{ visibility: "hidden" }}
                            ></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={`modal fade custom_modal `}
                  id="Serious222"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabIndex="-1"
                  aria-labelledby="SeriousLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog">
                    <div className="modal-content pb-60">
                      <button
                        type="button"
                        className="btn_close"
                        data-bs-dismiss="modal"
                      >
                        <img src={cross} alt="cross" className="cross_btn" />
                      </button>

                      <div className="modal-body">
                        <div className="modal_content">
                          <h1 className="f-26 text-center">
                            <span className="text_success">Note</span>
                          </h1>
                          {!Check_Completed_checks.includes(certificateData.paid_second)? (
                            <>
                              {" "}
                              {certificateData.attypayment == "no" &&  (
                                <p className="f-16 text-center mt-12 fw-700">
                                  The price for the course is
                                  <span className="text_success">
                                    {" "}
                                    $
                                    {certificateData.attypayment === "no" &&
                                    certificateData.couponcode_second !== ""
                                      ? 39.99
                                      : 19.99}{" "}
                                  </span>
                                </p>
                              )}
                              {certificateData.attypayment == null && (
                                <p className="f-16 text-center mt-12 fw-700">
                                  The price for the course is
                                  <span className="text_success"> $ 19.99</span>
                                </p>
                              )}
                            </>
                          ) : (
                            <></>
                          )}

                          {certificateData.attypayment == "yes" && (
                            <p className="f-16 text-center mt-12 fw-700">
                              Amount will be paid by your attorney
                            </p>
                          )}
                          {certificateData.paid_second === "" ? (
                            <>
                              {" "}
                              {certificateData.totalAmount !==
                                certificateData.amount &&
                                certificateData.attypayment == "no" && (
                                  <p className="f-16 text-center fw-700">
                                    Amount Payable
                                    <span className="text_success">
                                      {" "}
                                      $
                                      {Number(certificateData.amount).toFixed(
                                        2
                                      )}{" "}
                                    </span>
                                  </p>
                                )}
                            </>
                          ) : (
                            certificateData.attypayment == "no" && (
                              <p className="f-16 text-center mt-12 fw-700">
                                The amount for the course is paid
                              </p>
                            )
                          )}

                          <div className="btn_wrap text-center mt-40">
                            <button
                              className="btn_brnd-outline mr-22 mb-2"
                              data-bs-dismiss="modal"
                              type="button"
                            >
                              Go Back
                            </button>

                            <button
                              className=" btn_brnd  mt-20"
                              data-bs-dismiss="modal"
                              onClick={() => {
                                if (certificateData.paid_second === "") {
                                  if (
                                    certificateData.attypayment === "no" &&
                                    certificateData.amount > 0
                                  ) {
                                    setShowModalSecond(true);
                                  } else {
                                    handleSubmitSecond({
                                      enrollInfo: courseSecondInfo,
                                    });
                                  }
                                } else {
                                  handleSubmitSecond({
                                    enrollInfo: courseSecondInfo,
                                  });
                                }
                              }}
                              type={
                                (certificateData.attypayment === "yes" &&
                                  certificateData.amount === 0) ||
                                certificateData.paid_second !== ""
                                  ? "submit"
                                  : "button"
                              }
                            >
                              Confirm & Continue
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="media_object">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="media_object_img">
                        <img src={img1} alt="img1" />
                      </div>
                    </div>
                    <div className="col-md-9">
                      <h1 className="f-26">
                        How to Rebuild your Credit After Bankruptcy
                      </h1>

                      <p className="f-16">
                        Take this FREE class after you file to help you rebuild
                        your credit.
                      </p>

                      <button
                        type="button"
                        className=" btn_brnd  mt-20"
                        onClick={() => {
                          navigate("/720course");
                        }}
                      >
                        Start Now
                      </button>
                    </div>
                  </div>
                </div>
              </>
              {console.log(reTakecourseData)}
              {reTakecourseData &&
                Object.keys(reTakecourseData?.data).length > 0 && (
                  <h1 className="f-26 mt-60">My Retake Courses</h1>
                )}
              <>
                {/*///////////// 1st Course//////////////// */}
                {isLoggedIn &&
                user &&
                reTakecourseData &&
                reTakecourseData.data.perFirstCourse > 0 ? (
                  <div className="media_object">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="media_object_img">
                          <img src={img1} alt="img1" />
                        </div>
                      </div>
                      <div className="col-md-9">
                        <h1 className="f-26">Pre-Filing Credit Counseling</h1>
                        <h5 className="f-18 text_success">
                          Take Before Filing
                        </h5>

                        <p className="f-16">
                          Take our fast and informative US Trustee approved*
                          Credit Counseling class, and we'll send your required
                          certificate of completion straight to both you and
                          your attorney!
                        </p>
                        {/* ////////////////Budget analysis Button..........//// */}

                        {reTakecourseData.data.perFirstCourse > 33 && (
                          <Link
                            to={
                              userId
                                ? `/addinfomultistepuse?id=${userId}&retake=true`
                                : "/add-info-multistep?retake=true"
                            }
                            className="update-analysis"
                            style={{
                              fontSize: "16px",
                              textDecoration: "underline",
                            }}
                          >
                            Budget Analysis
                          </Link>
                        )}
                        {reTakecourseData.data.perFirstCourse === 100 &&
                          reTakecourseData.data.firstGenerated == "" && (
                            <span className="btn">Certificate Pending</span>
                          )}

                        <div className="row  mt-40" style={{ width: "98%" }}>
                          <div className="col-md-9">
                            <div className="progress_wrap">
                              <label className="f-18 f-700 mb-2">
                                {Math.round(
                                  reTakecourseData.data.perFirstCourse
                                )}
                                %
                              </label>
                              <ProgressBar
                                className="my-progress"
                                now={Math.round(
                                  reTakecourseData.data.perFirstCourse
                                )}
                              />

                              {reTakecourseData.data.perFirstCourse !== 100 && (
                                <p className="f-16 mt-12">
                                  {" "}
                                  {Math.round(
                                    (18 *
                                      (100 -
                                        reTakecourseData.data.perFirstCourse)) /
                                      100
                                  )}{" "}
                                  Classes are left
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="progress_btn">
                              {reTakecourseData.data.perFirstCourse !== 100 && (
                                <Link
                                  to={
                                    userId
                                      ? `/user-first-course?id=${userId}&retake=true`
                                      : verificationRequired === "true"
                                      ? "/security-law-questions?course=first&retake=true"
                                      : "/course?retake=true"
                                  }
                                >
                                  {" "}
                                  <button
                                    className="btn btn_brnd"
                                    type="submit"
                                  >
                                    Resume
                                  </button>
                                </Link>
                              )}

                              {reTakecourseData.data.perFirstCourse === 100 &&
                                reTakecourseData.data.firstGenerated &&
                                (reTakecourseData.data.firstDownload &&
                                reTakecourseData.data.firstDownload !==
                                  "yes" ? (
                                  <a
                                    href={reTakecourseData.data.firstDownload}
                                    target="_blank"
                                  >
                                    <button
                                      className="btn btn_brnd"
                                      type="submit"
                                    >
                                      Certificate
                                    </button>
                                  </a>
                                ) : (
                                  <button
                                    className="btn btn_brnd tooltips"
                                    type="submit"
                                  >
                                    Certificate
                                  </button>
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : isLoggedIn &&
                  user &&
                  reTakecourseData &&
                  reTakecourseData.data.perFirstCourse === 0 &&
                  (certificateData.activated_first === "checked" ||
                    certificateData.activated_first === "Checked" ||
                    certificateData.activated_first === "complete" ||
                    certificateData.activated_first === "Complete") ? (
                  <div className="media_object">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="media_object_img">
                          <img src={img1} alt="img1" />
                        </div>
                      </div>
                      <div className="col-md-9">
                        <h1 className="f-26">Pre-Filing Credit Counseling</h1>
                        <h5 className="f-18 text_success">
                          Take Before Filing
                        </h5>

                        <p className="f-16">
                          Take our fast and informative US Trustee approved*
                          Credit Counseling class, and we'll send your required
                          certificate of completion straight to both you and
                          your attorney!
                        </p>
                        {reTakecourseData.data.perFirstCourse > 33 && (
                          <Link
                            to={
                              userId
                                ? `/addinfomultistepuse?id=${userId}&retake=true`
                                : "/add-info-multistep?retake=true"
                            }
                            className="update-analysis"
                          >
                            Budget Analysis
                          </Link>
                        )}
                        <div className="row  mt-40" style={{ width: "98%" }}>
                          <div className="col-md-9">
                            <div className="progress_wrap">
                              <label className="f-18 f-700 mb-2">
                                {Math.round(
                                  reTakecourseData.data.perFirstCourse
                                )}
                                %
                              </label>
                              <ProgressBar
                                className="my-progress"
                                now={Math.round(
                                  reTakecourseData.data.perFirstCourse
                                )}
                              />

                              {reTakecourseData.data.perFirstCourse !== 100 && (
                                <p className="f-16 mt-12">
                                  {" "}
                                  {Math.round(
                                    (18 *
                                      (100 -
                                        reTakecourseData.data.perFirstCourse)) /
                                      100
                                  )}{" "}
                                  Classes are left
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="progress_btn">
                              {/* <p className="f-16 mb-0">class, 10 of 18</p> */}
                              <Link
                                to={
                                  userId
                                    ? `/user-first-course?id=${userId}&retake=true`
                                    : verificationRequired === "true"
                                    ? "/security-law-questions?course=first&retake=true"
                                    : "/course?retake=true"
                                }
                              >
                                <button className="btn btn_brnd" type="submit">
                                  Start Course
                                </button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  isLoggedIn &&
                  user &&
                  reTakecourseData && (
                    ///////////////////// 3rd condition//////////////////////////////////
                    <></>
                  )
                )}

                {/*2nd custom Modal */}

                {/*///////////// 2nd Course//////////////// */}

                {isLoggedIn &&
                user &&
                reTakecourseData &&
                reTakecourseData.data.perSecondCourse > 0 ? (
                  <div className="media_object">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="media_object_img">
                          <img src={img1} alt="img1" />
                        </div>
                      </div>
                      <div className="col-md-9">
                        <h1 className="f-26">
                          Post Bankruptcy debtor education
                        </h1>
                        <h5 className="f-18 text_success">Take After Filing</h5>

                        <p className="f-16">
                          Take our fast and informative US Trustee approved*
                          Credit Counseling class, and we'll send your required
                          certificate of completion straight to both you and
                          your attorney!
                        </p>
                        {reTakecourseData.data.perSecondCourse > 27 && (
                          <Link
                            to={
                              userId
                                ? `/net-worth-user?id=${userId}&retake=true`
                                : "/net-worth?from=dashboard&retake=true"
                            }
                            className="update-analysis mx-2"
                            style={{
                              fontSize: "16px",
                              textDecoration: "underline",
                            }}
                          >
                            Net worth worksheet
                          </Link>
                        )}
                        {reTakecourseData.data.perSecondCourse > 43 && (
                          <Link
                            to={
                              userId
                                ? `/spending-plan-user?id=${userId}&retake=true`
                                : "/spending-plan?from=dashboard&retake=true"
                            }
                            className="update-analysis mx-2"
                            style={{
                              fontSize: "16px",
                              textDecoration: "underline",
                            }}
                          >
                            Spending Plan
                          </Link>
                        )}

                        {/* {courseData.data.perSecondCourse > 33 && (
                        <Link
                          to={
                            userId
                              ? `/addinfomultistepuse?id=${userId}`
                              : "/add-info-multistep"
                          }
                          className="update-analysis mx-2"
                          style={{ fontSize: "16px" }}
                        >
                          Pay for this session
                        </Link>
                      )} */}
                        {reTakecourseData.data.perSecondCourse >= 0 && (
                          <a
                            href={reTakecourseData.data.courseMaterial}
                            target="_blank"
                            className="update-analysis mx-2"
                            style={{
                              fontSize: "16px",
                              textDecoration: "underline",
                            }}
                          >
                            Print course material
                          </a>
                        )}
                        {reTakecourseData.data.perSecondCourse === 100 &&
                          reTakecourseData.data.secondGenerated == "" && (
                            <span className="btn">Certificate Pending</span>
                          )}
                        <div className="row  mt-40" style={{ width: "98%" }}>
                          <div className="col-md-9">
                            <div className="progress_wrap">
                              <label className="f-18 f-700 mb-2">
                                {Math.round(
                                  reTakecourseData.data.perSecondCourse
                                )}
                                %
                              </label>
                              <ProgressBar
                                className="my-progress"
                                now={Math.round(
                                  reTakecourseData.data.perSecondCourse
                                )}
                              />

                              {reTakecourseData.data.perSecondCourse !==
                                100 && (
                                <p className="f-16 mt-12">
                                  {" "}
                                  {Math.round(
                                    (51 *
                                      (100 -
                                        reTakecourseData.data
                                          .perSecondCourse)) /
                                      100
                                  )}{" "}
                                  Classes are left
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="progress_btn">
                              {/* <p className="f-16 mb-0">class, 10 of 18</p> */}
                              {reTakecourseData.data.perSecondCourse !==
                                100 && (
                                <Link
                                  to={
                                    reTakecourseData.data?.quizSecondPending
                                      ? userId
                                        ? `/review-questions-user?id=${userId}&retake=true`
                                        : "/review-questions?retake=true"
                                      : userId
                                      ? `/user-second-course?id=${userId}&retake=true`
                                      : verificationRequired === "true"
                                      ? "/security-law-questions?course=second&retake=true"
                                      : "/2nd-course?retake=true"
                                  }
                                >
                                  {" "}
                                  <button
                                    className="btn btn_brnd"
                                    type="submit"
                                  >
                                    Resume
                                  </button>
                                </Link>
                              )}

                              {reTakecourseData.data.perSecondCourse === 100 &&
                                reTakecourseData.data.secondGenerated &&
                                (reTakecourseData.data.secondDownload &&
                                reTakecourseData.data.secondDownload !==
                                  "yes" ? (
                                  <a
                                    href={reTakecourseData.data.secondDownload}
                                    target="_blank"
                                  >
                                    <button
                                      className="btn btn_brnd"
                                      type="submit"
                                    >
                                      Certificate
                                    </button>
                                  </a>
                                ) : (
                                  <button
                                    className="btn btn_brnd tooltips"
                                    type="submit"
                                  >
                                    Certificate
                                  </button>
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : isLoggedIn &&
                  user &&
                  reTakecourseData &&
                  reTakecourseData.data.perSecondCourse === 0 &&
                  (certificateData.activated_second === "checked" ||
                    certificateData.activated_second === "Checked" ||
                    certificateData.activated_second === "complete" ||
                    certificateData.activated_second === "Complete") ? (
                  <div className="media_object">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="media_object_img">
                          <img src={img1} alt="img1" />
                        </div>
                      </div>
                      <div className="col-md-9">
                        <h1 className="f-26">
                          Post Bankruptcy debtor education
                        </h1>
                        <h5 className="f-18 text_success">Take After Filing</h5>

                        <p className="f-16">
                          Take our fast and informative US Trustee approved*
                          Credit Counseling class, and we'll send your required
                          certificate of completion straight to both you and
                          your attorney!
                        </p>
                        {reTakecourseData.data.perSecondCourse >= 0 && (
                          <a
                            href={reTakecourseData.data.courseMaterial}
                            target="_blank"
                            className="update-analysis mx-2"
                            style={{
                              fontSize: "16px",
                              textDecoration: "underline",
                            }}
                          >
                            Print course material
                          </a>
                        )}
                        <div className="row  mt-40" style={{ width: "98%" }}>
                          <div className="col-md-9">
                            <div className="progress_wrap">
                              <label className="f-18 f-700 mb-2">
                                {Math.round(
                                  reTakecourseData.data.perSecondCourse
                                )}
                                %
                              </label>
                              <ProgressBar
                                className="my-progress"
                                now={Math.round(
                                  reTakecourseData.data.perSecondCourse
                                )}
                              />

                              {reTakecourseData.data.perSecondCourse !==
                                100 && (
                                <p className="f-16 mt-12">
                                  {" "}
                                  {Math.round(
                                    (51 *
                                      (100 -
                                        reTakecourseData.data
                                          .perSecondCourse)) /
                                      100
                                  )}{" "}
                                  Classes are left
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-3">
                            {certificateData.activated_second &&
                            certificateData.bankruptcycasenum ? (
                              <div className="progress_btn">
                                <Link
                                  to={
                                    userId
                                      ? `/user-second-course?id=${userId}&retake=true`
                                      : verificationRequired === "true"
                                      ? "/security-law-questions?course=second&retake=true"
                                      : "/2nd-course?retake=true"
                                  }
                                >
                                  <button
                                    className="btn btn_brnd"
                                    type="button"
                                    // data-bs-toggle="modal"
                                    // data-bs-target="#SecondSerious"
                                  >
                                    Start Course
                                  </button>
                                </Link>
                              </div>
                            ) : (
                              <div className="progress_btn">
                                <button
                                  className="btn btn_brnd"
                                  type="button"
                                  data-bs-toggle="modal"
                                  data-bs-target="#SecondSerious"
                                >
                                  Start Course
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </>
            </>
          )}
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default DashboardPage;
