import React from "react";
import Congratulation from "../../assets/images/SiteImg/congratulation.png";
import Header from "../../layouts/header";
import Footer from "../../layouts/footer";
import { useSelector } from "react-redux";

const CongratulationMassage = () => {
  const user = useSelector((state) => state.user.user.data);
  return (
    <div>
      <Header />
      <section className=" mt-250">
        <div className="theme_container">
          <div className="media_object">
            <div className="congrationlaton_Wrap">
              <div className="congrationlaton-img">
                <img src={Congratulation} alt="congratulation" />
              </div>
              <h1 className="f-26 text_success f-700 after_text">
                Congratulations{" "}
                {localStorage.getItem("isJoint") === "true"
                  ? user.joint_firstname === "" && user.joint_lastname === ""
                    ? user.firstname + " " + user.lastname + "'s Joint"
                    : user.joint_firstname +
                      " " +
                      user.joint_lastname +
                      " (Joint)"
                  : user.firstname + " " + user.lastname}
              </h1>
              <p className="f-16 mt-60">
              Congratulations! Your course is now complete. You will receive an email with your certificate of completion within the next 24 hours.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default CongratulationMassage;
